/* AllVotersPage.css */
.all-voters-page-container {
   /* padding: 16px;*/ /* Add padding to the container to give some space around the content */
  }
  
  .table-container {
    overflow-x: auto; /* Add horizontal scroll for the table on smaller screens */
  }
  
  /* Media query for responsiveness */
  @media (max-width: 768px) {
    .table-container {
      width: 100%; /* Set the table container width to 100% on smaller screens */
    }
  }
  