/* EmailEditor.css */

/* Custom styles for the editor */
.demo-wrapper {
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
}

.demo-editor {
  min-height: 200px;
  padding: 10px;
}

  