/* AdminMenu.css */

/* Add responsive styling for smaller screens (up to 768px) */
@media (max-width: 768px) {
    .ant-menu-horizontal {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .ant-menu-horizontal .ant-menu-item {
      margin: 5px 0;
    }
  }
  
  /* Add additional styles for larger screens (beyond 768px if needed) */
  /* For example, you can adjust the spacing and font size for larger screens */
  
  /* .ant-menu-horizontal .ant-menu-item {
      margin: 0 10px;
      font-size: 16px;
  } */
  